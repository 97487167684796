<script>
import {getUserById, uploadFile, userUpdate} from "@/api/common";
import PageHeader from "@/components/PageHeader.vue";
import {getDistributor} from "@/api/apis";

export default {
  name: "EditStaff",
  components: {PageHeader},
  data(){
    return {
      id: null,
      page_form_loading: false,
      // 员工表单
      pageForm: {
        username: '',
        name: '',
        gender: '',
        phone: '',
        tmp_organization_id: [],
        developersId: '',
        distributorId: '',
        userType: '',
        // isSalesman: '',
        isTencentSignSuperAdmin: false,
        idNo: '',
        password: '',
        twoPassword: '',
        headImg: ''
      },
      pageRules: {
        username: [
          {required: true, message: '请输入账号', trigger: 'blur'},
        ],
        name: [
          {required: true, message: '请输入姓名', trigger: 'blur'},
        ],
        gender: [
          {required: true, message: '请选择性别', trigger: 'blur'},
        ],
        phone: [
          {required: true, message: '请输入手机号', trigger: 'blur'},
        ],
        tmp_organization_id: [
          {required: true, message: '请选择组织', trigger: 'blur'},
        ],
        userType: [
          {required: true, message: '请选择用户类型', trigger: 'blur'},
        ],
      },
      // 组织管理
      organizationalData: [],
    }
  },
  mounted() {
    this.id = this.$route.params.id
    if (this.id) {
      this.getInfo()
    }
    this.getDistributorV2()
  },
  methods: {
    handleThumbUpload(source, formName) {
      let that = this
      uploadFile(source.file).then(res => {
        if(formName === 'devPageForm'){
          that.devPageForm.businessLicenseImg = res
        } else {
          that.pageForm.headImg = res;
        }
        that.$forceUpdate();
      })
    },
    getInfo(){
      getUserById(this.id).then(res => {

        const item = res

        let tmp_organization_id = []
        if(item.userType === 'enterprise') {
          if (item.developersId) {
            tmp_organization_id.push( item.developersId)
          }
        } else if(item.userType === 'distributor'){
          if (item.distributorId) {
            tmp_organization_id.push( item.distributorId)
          }
        }

        this.pageForm = {
          id: item.id,
          username: item.username,
          name: item.name,
          gender: item.gender,
          phone: item.phone,
          tmp_organization_id: tmp_organization_id,
          developersId: item.developersId,
          distributorId: item.distributorId,
          userType: item.userType,
          isTencentSignSuperAdmin: item.isTencentSignSuperAdmin,
          idNo: item.idNo,
          headImg: item.headImg,
          password: '',
          twoPassword: '',
          version: item.version
        }
        this.page_dialog_visible = true
      })
    },
    /**
     *
     */
    organizationChangeV2(){
      const nodes = this.$refs.organizationCascaderRef.getCheckedNodes()
      if (nodes.length < 1) {
        return
      }
      const current = nodes[nodes.length - 1]
      // console.log(current.data, '-----current-');
      // ROOT DEALER-经销商 DEVELOPER-开发商
      switch (current.data.nodeType) {
        case 'ROOT':
          this.pageForm.userType = 'admin'
          this.pageForm.developersId = ''
          this.pageForm.distributorId = ''
          this.pageForm.isTencentSignSuperAdmin = true
          break
          // 经销商
        case 'DEALER':
          this.pageForm.userType = 'distributor'
          this.pageForm.developersId = current.data.developersId
          this.pageForm.distributorId = current.data.id
          break
          // 开发商
        case 'DEVELOPER':
          this.pageForm.userType = 'enterprise'
          this.pageForm.developersId = current.data.id
          this.pageForm.distributorId = ''
          break
      }
    },
    /**
     * 组织管理
     */
    getDistributorV2(){
      this.organizationalData = []
      getDistributor().then(res => {
        const {isAdmin, isDevelopers} = res
        this.isAdmin = isAdmin
        this.isDevelopers = isDevelopers
        res.data.map(iter => {
          iter.node_id = `DEVELOPER_${iter.id}`
          iter.nodeType = 'DEVELOPER'
          if (iter.distributorList && iter.distributorList.length > 0) {
            iter.distributorList.map(item => {
              item.node_id = `DEALER_${item.id}`
              item.nodeType = 'DEALER'
            })
          }
        })
        this.organizationalData = res.data
      })

      // this.organizationalData = []
      // getDistributor().then(res => {
      //   //
      //   const { isAdmin, isDevelopers } = res
      //   this.isAdmin = isAdmin
      //   this.isDevelopers = isDevelopers
      //
      //   // -
      //   const data = {
      //     id: -1,
      //     label: COMPANY_NAME,
      //     children: [],
      //     nodeType: 'ROOT'
      //   }
      //   // ROOT DEALER-经销商 DEVELOPER-开发商
      //   // 经销商
      //   const developerData = res.data
      //   developerData.map(iter => {
      //     iter.children = iter.distributorList || []
      //     if (iter.distributorList) {
      //       iter.distributorList.map(item => {
      //         item.label = item.name + `(经销商)`
      //         item.nodeType = 'DEALER'
      //       })
      //     }
      //   })
      //
      //   // 开发商
      //   res.data.forEach(iter => {
      //     if (iter.distributorList) {
      //       data.children.push({
      //         ...iter,
      //         label: iter.name + `(开发商)`,
      //         nodeType: 'DEVELOPER'
      //       })
      //     } else {
      //       data.children.push({
      //         ...iter,
      //         label: iter.name + `(经销商)`,
      //         nodeType: 'DEALER'
      //       })
      //     }
      //   })
      //   this.organizationalData.push(data)
      // })
    },
    submit(ref) {
      let that = this
      that.$refs[ref].validate((valid) => {
        if (!valid) {
          return false
        }
        that.page_form_loading = true

        userUpdate({...that.pageForm, id: this.id}).then(() => {
          that.page_form_loading = false
          that.$message.success('提交成功')
          this.$router.replace('/admin/organization')
        }).catch(() => {
          that.page_form_loading = false
        })
      })
    },
    cancel(){
      this.$router.replace('/admin/organization')
    }
  }
}
</script>

<template>
  <div class="page-container">
    <page-header></page-header>
    <div class="dashboard-container">
      <div class="page-card">
        <div class="page-card-header">
          <span style="font-weight: bold;">{{ $route.meta.title }}</span>
        </div>
        <div class="page-card-body">
          <el-form :model="pageForm"
                   :rules="pageRules"
                   label-width="120px"
                   ref="pageForm"
                   inline label-position="top"
                   v-loading="page_form_loading">
            <el-row>
              <el-col :span="5">
                <el-form-item label="账号" prop="username" style="width: 100%;">
                  <el-input placeholder="账号" v-model="pageForm.username" disabled></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="5" :offset="1">
                <el-form-item label="姓名" prop="name" style="width: 100%;">
                  <el-input placeholder="姓名" v-model="pageForm.name"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="5" :offset="1">
                <el-form-item label="性别" prop="gender" style="width: 100%;">
                  <el-select style="width: 100%;" v-model="pageForm.gender" placeholder="性别">
                    <el-option label="男" :value="0"></el-option>
                    <el-option label="女" :value="1"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="5" :offset="1">
                <el-form-item label="身份证号" prop="idNo" style="width: 100%;"
                              :rules="[{required: true, message: '请输入身份证号', trigger: 'blur'},]">
                  <el-input placeholder="身份证号" v-model="pageForm.idNo"></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="5">
                <el-form-item label="手机号" prop="phone" style="width: 100%;">
                  <el-input placeholder="手机号" v-model="pageForm.phone"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="5" :offset="1">
                <el-form-item label="是否为电子签超管" prop="isTencentSignSuperAdmin" style="width: 100%;">
                  <el-checkbox v-model="pageForm.isTencentSignSuperAdmin"></el-checkbox>
                </el-form-item>
              </el-col>
              <el-col :span="5" :offset="1">
                <el-form-item label="归属组织" prop="tmp_organization_id" style="width: 100%;">
                  <el-cascader style="width: 100%;"
                               ref="organizationCascaderRef"
                               v-model="pageForm.tmp_organization_id"
                               :options="organizationalData"
                               :show-all-levels="false"
                               @change="organizationChangeV2"
                               :props="{
                                 checkStrictly: true,
                               label: 'name',
                               value: 'id' ,
                               children: 'distributorList' }"></el-cascader>
                </el-form-item>
              </el-col>
              <el-col :span="5" :offset="1">
                <el-form-item label="用户类型" prop="userType" style="width: 100%;">
                  <el-select style="width: 100%;" disabled v-model="pageForm.userType" placeholder="用户类型">
                    <el-option label="集团用户" value='admin'/>
                    <el-option label="企业用户" value='enterprise'/>
                    <el-option label="经销商" value='distributor'/>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="5" >
                <el-form-item label="头像" prop="headImg">
                  <el-upload action="-"
                      class="el-uploader"
                      style="--image-width: 100px;--image-height: 100px"
                      :show-file-list="false"
                      :http-request="handleThumbUpload">
                    <img v-if="pageForm.headImg" :src="pageForm.headImg | getSourceUri"
                         class="el-uploader-image">
                    <i v-else class="el-icon-plus el-uploader-icon"></i>
                    <div class="el-upload__tip" slot="tip">只能上传image文件 1:1</div>
                  </el-upload>
                </el-form-item>
              </el-col>
              <el-col :span="5" :offset="1">
                <el-form-item style="width: 100%;" label="密码" prop="password" :rules="pageForm.hasOwnProperty('id') ? [] : [{required: true, message: '请输入账号', trigger: 'blur'}]">
                  <el-input placeholder="密码" v-model="pageForm.password"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="5" :offset="1">
                <el-form-item style="width: 100%;" label="确认密码" prop="twoPassword" :rules="pageForm.hasOwnProperty('id') ? [] : [{required: true, message: '请输入账号', trigger: 'blur'}]">
                  <el-input placeholder="确认密码" v-model="pageForm.twoPassword"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="5" :offset="1"></el-col>
            </el-row>

            <div class="dialog-footer">
              <el-button @click="cancel">取 消</el-button>
              <el-button @click="submit('pageForm')" type="primary">确 定</el-button>
            </div>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.page-container{
  .dashboard-container{
    .page-card{
      .page-card-header{
        padding-bottom: 0;
        flex-direction: column;
        align-items: flex-start;
        border-bottom: none;
        &::after{
          padding-top: 20px;
          content: ' ';
          width: 100%;
          height: 1px;
          border-bottom: 1px solid #ebeef5;
        }
      }
    }
  }
}
.page-card-body {
  padding: 20px;

  .table-action-item {
    display: inline-block;
    cursor: pointer;
    color: $--color-primary;

    & + .table-action-item {
      margin-left: 10px;
    }
  }
}
</style>